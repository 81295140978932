// html {
//   scroll-behavior: smooth;
// }

html,
body {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow-x: hidden;
}
