@import '../../public/css/cera-round-pro.css';

html,
body {
  font-family: $typography-ceraroundpro;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $typography-color;
  letter-spacing: $typography-letter-spacing;
}
