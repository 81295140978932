//Import abstracts
@import "abstracts/variables";

//Import base
@import "base/typography";

// import react player
@import 'react-h5-audio-player/src/styles.scss';

.simple-audio-player.rhap_container {
    border: 0 none;
    box-shadow: none;

    .rhap_main.rhap_horizontal-reverse {
        .rhap_additional-controls:empty {
            display: none;
        }

        .rhap_volume-controls:empty {
            display: none;
        }

        .rhap_controls-section {
            flex: 0 1 auto;
        }

        .rhap_progress-section {
            flex: 1 1 auto;
        }
    }
}


.iframe-wrapper {
    position: relative;

    video,
    iframe {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        position: absolute
    }
}
