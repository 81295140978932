/* REGULAR */
@font-face {
    font-family: 'CeraRoundPro';
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/CeraRoundPro-Regular.otf");
  }

/* MEDIUM */
@font-face {
    font-family: 'CeraRoundPro';
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/CeraRoundPro-Medium.otf");
}

/* BODL */
@font-face {
    font-family: 'CeraRoundPro';
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/CeraRoundPro-Bold.otf");
}

/* BLACK */
@font-face {
    font-family: 'CeraRoundPro';
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/CeraRoundPro-Black.otf");
}
